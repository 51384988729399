@font-face {
  font-family: 'Ciutadella';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url('./Ciutadella-Regular\ Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Ciutadella';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url('./Ciutadella-Bold\ Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Ciutadella';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url('./Ciutadella-Light\ Italic.otf') format('woff2');
}
@font-face {
  font-family: 'Ciutadella';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url('./Ciutadella-Regular.otf') format('woff2');
}
@font-face {
  font-family: 'Ciutadella';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url('./Ciutadella-Bold.otf') format('woff2');
}
@font-face {
  font-family: 'Ciutadella';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url('./Ciutadella-Light.otf') format('woff2');
}
